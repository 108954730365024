
.processContainer {
    width: 100vw; 
    height: 100vh; 
    position: relative;
    box-sizing: border-box;
    padding: 2vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .processContent {
        width: 40vw;
        p {
            margin-top: 0;
            font-size: clamp(15px, 1.5vw, 20px);    
        }
    }
}

.headlineProcess {
    padding: 2vw;
    top: 5vw !important;
    position: sticky;
    font-size: clamp(15px, 1.5vw, 20px);
    z-index: 200;
    background-color: white;
}


@media (max-width: 767px) {
    .processContainer {
        height: 200vh;
        .processContent {
            width: 99vw;
            top: 10vw;
        }
    }
    .headlineProcess {
        top: 7vw !important;
    }
}

