.slideShowContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.2;
  }

  .blurryOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw;
    box-sizing: border-box;
    background-color: black;
    opacity: 0.75;
    width: 70vw;
    filter: blur(10px);
    h1 {
      opacity: 0;
    }
  }

  .textOverlay {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw;
    box-sizing: border-box;
    width: 70vw;
    h1 {
      font-size: clamp(20px, 2.25vw, 35px);
      opacity: 1;
    }
  }
}

@media (max-width: 767px) {
  .slideShowContainer {
    .textOverlay {
        width: 90vw;
    }
    .blurryOverlay {
      width: 100vw;
      height: 90vw;
    }
  }
}
