.projectDiv {
    width: 50vw;
    margin-left: 19.5vw;
    padding: 2vw; 
    padding-top: 10vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    .textDiv {
        margin-left: 2vw;    
        width: 40vw;
        h1 {
            font-size: clamp(15px, 1.5vw, 25px);
            margin-top: 0;
        }
        h2 {
            font-size: clamp(15px, 1.5vw, 25px);
            margin-top: 0;
        }
        p {
            font-size: clamp(15px, 1.5vw, 25px);
            margin-top: 0;
        }
        ul {
            font-size: 1.25vw;
            margin-top: 0;
        }
        iframe {
            margin-bottom: 3vw;
        }
    }
    img {
        width: 20vw; 
    }
}

@media (max-width: 767px) {
    .projectDiv {
        grid-template-columns: 1fr;
        width: 100vw;
        margin-left: 0;
        box-sizing: border-box;
        .textDiv {
            width: 96vw;
            margin-top: 5vw;
            margin-left: 0vw;    
        }
        img {
            width: 96vw;
        }
    }
}