.header {
  width: 100vw;
  background-color: white;    
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  position: fixed;
  padding: 2vw;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  color: black;
  z-index: 999999999;
  
  h1 {
    margin: 0;
    font-size: clamp(12px, 1.5vw, 25px);
  }
  a:any-link {
    color: black;
    text-decoration-color: black;
    text-decoration: none;
  }
  .languageSelector {
    h1 {
      display: inline-block !important;
      margin-right: 1vw;
    }
    h1:last-child {
      margin-right: 0;
    }
    h1:hover {
      cursor: pointer;
    }
  }
}
