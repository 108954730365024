.archiveContainer {
  position: relative;
  box-sizing: border-box;
  padding: 2vw;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  margin-bottom: 5vh;
  overflow: hidden;
  .mainFilter {
    display: flex;
    margin-bottom: 3vw;
    .mainFilterItem {
      padding: 1vw;
      margin: 0;
      font-size: clamp(15px, 1.5vw, 20px);
      margin: 1vw;
      margin-left: 0;
      margin-top: 0;
      opacity: 0.5;
    }
  }
  .archive {
    width: 70vw;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 33% 33% 33%;  
    gap: 1vw;
    .archiveItem {
      aspect-ratio: 1;
      position: relative;
      transition: .5s ease;
      .imageContainer {
        width: 100%;
        height: 100%;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        .text {
          font-size: 25px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
        }
      }
      .overlay:hover {
        opacity: 0.9;
      }
    }
  }

  @keyframes fadeIn {
    from { opacity: 0;}
    to { opacity: 1;}
  }

  .archiveNav {
    width: 30vw;
    align-items: start;
    display: inline-block;
    margin-bottom: 2vw;
    .navItem {
      background-color: lightgray;
      display: inline;
      margin: 1vw;
      box-sizing: border-box;
      padding: 1vw;
      white-space: nowrap;
      display: inline-block;
      height: auto !important;
      font-size: clamp(15px, 1.5vw, 20px);
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.headline {
  padding: 2vw;
  top: 5vw !important;
  position: sticky;
  font-size: clamp(15px, 1.5vw, 20px);
  z-index: 200;
}

@media (max-width: 767px) {
  .archiveContainer {
    display: inline-block;
    .archive {
      width: 96vw;
      grid-template-columns: 1fr;
    }
    .archiveItem {
      width: 96vw !important;
      height: 96vw !important;
    }
    .archiveNav {
      width: 50vw;
    }
  }

  .headline {
    top: 7vw !important;
  }
}
