.footer {
    width: 100vw; 
    font-size: clamp(6px, 0.5vw, 40px);
    background-color: white;
    display: flex;
    align-items: center;  
    justify-content: space-between;
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    padding: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    color: black;
    h1 {
      margin: 0;
    }
    a:any-link {
      color: black;
      text-decoration-color: black;
      text-decoration: none;
    }
}
  
  