.contactDiv {
    padding: 2vw; 
    padding-top: 10vw;
    h1 {
        font-size: clamp(25px, 1vw, 50px);
    }
    h3 {
        margin-bottom: 0;
    }
    p {
        max-width: 35vw;
        font-size: clamp(15px, 1vw, 50px);
        margin-top: 0;
    }
}