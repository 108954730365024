.participantsContainer {
    width: 100vw; 
    height: 100vh; 
    position: relative;
    box-sizing: border-box;
    padding: 2vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .participantsContent {
        width: 40vw;
        p {
            font-size: clamp(15px, 1.5vw, 20px);
        }
        h1 {
            font-size: clamp(15px, 1.5vw, 20px);
        }
    }
}

.headline {
    padding: 2vw;
    top: 5vw !important;
    position: sticky;
    font-size: clamp(15px, 1.5vw, 20px);
    background-color: white;
}

@media (max-width: 767px) {
    .participantsContainer {
        .participantsContent {
            width: 99vw;
        }
    }
    .headline {
        top: 7vw !important;
    }
}

